import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError( (err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            console.error('Error Event');
          } else {
            console.log(`error status : ${err.status} ${err.statusText}`);
            switch (err.status) {
              case 401:
                // auto logout if 401 response returned from api
                this.authService.logout();
                location.reload();
                break;
              case 403:
                // auto logout if 403 response returned from api
                this.authService.logout();
                location.reload();
                break;
            }
          }
        } else {
          console.error('some thing else happened');
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}

import { NgModule } from '@angular/core';

import {
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    // MatButtonToggleModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    // MatGridListModule,
    MatIconModule,
    MatNativeDateModule,
    // MatRadioModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDialogModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatRippleModule,
    MatSliderModule,
    MatTreeModule,
} from '@angular/material';

@NgModule({
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatButtonModule,
        // MatButtonToggleModule,
        // MatRadioModule,
        MatSidenavModule,
        MatCardModule,
        MatChipsModule,
        MatListModule,
        MatExpansionModule,
        MatTabsModule,
        MatIconModule,
        // MatGridListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatDialogModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatRippleModule,
        MatSliderModule,
        MatTreeModule,
    ],
    exports: [
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatButtonModule,
        // MatButtonToggleModule,
        // MatRadioModule,
        MatSidenavModule,
        MatCardModule,
        MatChipsModule,
        MatListModule,
        MatExpansionModule,
        MatTabsModule,
        MatIconModule,
        // MatGridListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatDialogModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatRippleModule,
        MatSliderModule,
        MatTreeModule,
    ],
})
export class MaterialModule { }

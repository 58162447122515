import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const requestUrl: string = request.url;
    if (requestUrl.match(/\/files\/download\//)) {
      const clonedRequest: HttpRequest<any> = request.clone({
      });
      return next.handle(clonedRequest);
    }

    if (requestUrl.match(/\/files\/upload/)) {
      const clonedRequest: HttpRequest<any> = request.clone({
      });
      return next.handle(clonedRequest);
    }

    if (requestUrl.match(/\/geoserver\/atrrgis\/wms/)) {
      const clonedRequest: HttpRequest<any> = request.clone({
        url: 'https://ggk.kz' + requestUrl,
        headers: request.headers.set('Authorization', `Basic YXRycmdpczpQN1BxdTI=`)
      });
      return next.handle(clonedRequest);
    }

    const token = this.authService.getToken();
    if (token) {
      const req = request.clone({
        headers: request.headers.append(
          'Authorization', `Bearer ${token}`
        )
      });
      return next.handle(req);
    }

    return next.handle(request);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import {TOKEN_STORAGE_KEY} from '@shared/models/auth.model';

import { ApiService } from '@core/http/api/api.service';
import { CONFIG } from '@app/configs/config';
import { User } from '@shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser$: Observable<User>;
  private jwtHelper: JwtHelperService = new JwtHelperService();

  public redirectUrl: string;

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setCurrentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  public getTokenData() {
    const token = localStorage.getItem('access_token');
    return this.jwtHelper.decodeToken(token);
  }

  public getCurrentUser(): any {
    const tokenData = this.getTokenData();
    if (tokenData && tokenData.userId) {
      localStorage.setItem('userId', tokenData.userId);
      return this.api.get(CONFIG.EQYZMET_SERVICES_URL + `users/${tokenData.userId}`);
    }
    return null;
  }

  login(username: string, password: string) {
    return this.http.post(`/auth/token`, { username, password });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
    this.currentUserSubject.next(null);
    this.router.navigate(['signin']);
  }

  setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  public get tokenStorageKey(): string {
    return TOKEN_STORAGE_KEY;
  }

  public get tokenFromStorage(): string {
    return localStorage.getItem(TOKEN_STORAGE_KEY) || '';
  }

}

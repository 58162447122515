import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GestureConfig } from '@angular/material';
import { JwtModule } from '@auth0/angular-jwt';
import { RoutingModule } from './routing.module';

import { AppComponent } from './app.component';
import { DirectivesModule } from './shared/directives/directives.module';
import { ServicesModule } from './core/services/services.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { TranslocoRootModule } from './transloco-root.module';
import { ImgOpenDialogModule } from './shared/components/img-open-dialog/img-open-dialog.module';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        ServicesModule.forRoot(),
        DirectivesModule,

        RoutingModule,
        HttpClientModule,
        TranslocoRootModule,
        ImgOpenDialogModule,
        // JwtModule.forRoot({
        //   config: {
        //     tokenGetter: (() => localStorage.getItem('access_token')),
        //     whitelistedDomains: ['localhost:3000'],
        //     blacklistedRoutes: ['http://localhost:3000/auth/login']
        //   }
        // }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
